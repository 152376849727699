import axios from "axios";
import { useEffect, useState } from "react";



export default function useCategory() {
    const [categories, setCategories] = useState([]);


    const getCategories = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/category/get-category`);
            setCategories(data?.category)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getCategories();
    }, [])


    return categories;
}

